import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import RegularAd from './RegularAd';
import NativeAd from './NativeAd';
import { getPlacement, DEFAULT_AD_HREF } from '../../../utils/ads/adsUtils';

const AdSlot = ({ adParams, adSlot, pageAdReload, enableAdRefresh }) => {
  const zone = adParams && adParams[adSlot];
  const placement = zone?.placement || {};
  if (Object.keys(placement).length === 0 || placement.length === 0) {//Review this condition deeply
    if (zone?.defaultImageURL) {
      /* eslint-disable camelcase */
      placement.redirect_url = DEFAULT_AD_HREF;
      placement.image_url = zone.defaultImageURL;
      /* eslint-disable camelcase */
      return <RegularAd adParams={adParams} adSlot={adSlot} adPlacement={placement} />;
    }
    return null;
  }

  const { adPlacement, isNativeAd } = getPlacement({ placement, pageAdReload, enableAdRefresh });

  return isNativeAd ?
    <NativeAd adParams={adParams} adSlot={adSlot} adPlacement={adPlacement} /> :
    <RegularAd adParams={adParams} adSlot={adSlot} adPlacement={adPlacement} />;
};

AdSlot.propTypes = {
  adParams: PropTypes.object,
  adSlot: PropTypes.string,
  pageAdReload: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string
  ]),
  enableAdRefresh: PropTypes.bool
};

export default AdSlot;
