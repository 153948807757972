import React from 'react';
import { Ad } from '@dmm/react-common-components';
import PropTypes from 'prop-types';
import AdSlot from '../AdSlot';

const AdDisplayer = ({ adSlot, newAdsConfig, googleAdParams, pageAdReload, enableAdRefresh }) => {
  if (newAdsConfig && adSlot) {
    return <AdSlot adParams={newAdsConfig} adSlot={adSlot} pageAdReload={pageAdReload} enableAdRefresh={enableAdRefresh} />;
  }
  if (googleAdParams) {
    return (<Ad {...googleAdParams} />);
  }
  return null;
};

AdDisplayer.propTypes = {
  adSlot: PropTypes.string,
  newAdsConfig: PropTypes.object,
  googleAdParams: PropTypes.object,
  pageAdReload: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string
  ]),
  enableAdRefresh: PropTypes.bool
};
export { AdDisplayer };
